import React from "react";
import { mainTags } from "../constants";
import { compactObject } from "../utilities/CompactObject";
import formatDate from "../utilities/FormatBlogDate";
import { _slugify, removeExpressions, sanatizeSearchText } from "../utilities/helpers";
import { Link } from "gatsby";


/**
 * @summary A single blog card
 * @param {Object} {blog} a blog object from prismic
 * @returns JSX.Element
 */

const BlogCard = ({ blog, location, searchText, allowSubDetails = true }) => {
  const regex = new RegExp(sanatizeSearchText(searchText), "iu");
  const params = new URLSearchParams(location.search);
  const _tag = params.get("tag");
  const content = blog?.node?.data?.title?.text?.replace(
    regex,
    `<span style="background:yellow">${blog?.node?.data?.title?.text?.match(
      regex
    )}</span>`
  );

  const { image, sub_image, content: description, read_time } = blog.node?.data;

  const publish_date =
    blog?.node?.custom_publish_date || blog?.node?.first_publication_date;
  return (
    <div className="flex flex-col bg-white rounded-lg shadow-lg card-3d">
      <div className="relative h-56">
        <Link
          to={
            blog?.node?.tags?.includes("Customers")
              ? `/customers/${blog?.node?.uid}/`
              : `/blog/${blog?.node?.uid}/`
          }
          className="relative w-full h-full"
          state={{ prevPath: location?.pathname }}
        >
          <img
            src={image?.url}
            width={image?.gatsbyImageData?.width}
            height={image?.gatsbyImageData?.height}
            alt={`sampleImage_${blog?.node?.uid}`}
            className="object-cover w-full h-full rounded-t-lg"
            loading="lazy"
          />
        </Link>
        {sub_image?.url && (
          <div className="absolute flex items-center justify-center h-20 px-2 bg-white rounded shadow-lg w-28 right-4 -bottom-10">
            <img
              src={sub_image?.url}
              alt={`sampleImage_${blog?.node?.uid}`}
              className="object-cover w-auto h-auto"
              loading="lazy"
              width={sub_image?.gatsbyImageData?.width}
              height={sub_image?.gatsbyImageData?.height}
            />
          </div>
        )}
      </div>
      <div className="flex min-h-[180px] flex-1 flex-col items-start px-5 pt-8 pb-6">
        <div className="flex flex-wrap items-center justify-between w-full gap-1">
          {blog?.node?.tags?.map((tag, i) => {
            const slug = _slugify(tag);
            const query = compactObject({
              tag: _tag ? _tag : mainTags.includes(tag) ? slug : "",
              sub: mainTags.includes(tag) ? "" : slug,
            });
            return (
              <>
                {blog?.node?.tags?.includes("Customers") ? (
                  <div
                    key={`customers_tag_${i}`}
                    className="text-sm font-semibold tracking-wider text-purple-500 uppercase"
                  >
                    {tag}
                  </div>
                ) : (
                  <Link
                    key={`blog_tag_${i}`}
                    state={{ prevPath: location?.pathname }}
                    to={`/blog?${new URLSearchParams(query).toString()}`}
                    className="text-sm font-semibold tracking-wider text-purple-500 uppercase"
                  >
                    {tag}
                  </Link>
                )}
              </>
            );
          })}
        </div>

        <div className="flex flex-col justify-between flex-1 w-full">
          <Link
            state={{ prevPath: location?.pathname }}
            to={
              blog?.node?.tags?.includes("Customers")
                ? `/customers/${blog?.node?.uid}/`
                : `/blog/${blog?.node?.uid}/`
            }
            className="mt-5 text-xl font-medium line-clamp-3 hover:text-purple-500"
          >
            <span
              className="line-clamp-2"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </Link>

          <p className="mt-4 text-sm line-clamp-3 text-gray-1200">
            {removeExpressions(description?.text)}
          </p>

          <div className="flex items-center justify-between w-full mt-5 mb-1">
            {allowSubDetails && (
              <div className="flex items-center justify-between flex-1">
                {publish_date && (
                  <p className="text-xs text-gray-1200">
                    {publish_date ? formatDate(publish_date) : "--"}
                  </p>
                )}

                {read_time && (
                  <p className="text-xs text-gray-1200">{read_time} min</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
